<template>
  <div>
    <div class="err-msg">{{errMsg}}</div>
  </div>
</template>

<script>
import {setToken, getToken} from '@/lib/utils'
import {login} from '@/api/index.js'

export default {
  name: 'auth',
  data() {
    return {
      show: false,
      errMsg: ''
    }
  },
  created() {
    this.errMsg = '';
    var token = getToken();
    if(token) {
      this.$router.push({path: '/', query: {_replace:true}})
    } else {
      this.target = this.$route.query.target || '/'
      var code = this.$route.query.json
      if (!code) {
        this.errMsg = '登录失败,请从工厂小程序进入平台';
      } else {
        this.form = {
          code: code
        }
        this.login()
      }
    }
  },
  methods: {
    login() {
      login(this.form)
        .then(res => {
          if (res.code == 0) {
            if (this.target) {
              setToken(res.data.token, res.data.token_limit);
              this.$store.dispatch('user/initUser', {reload: true, success: ()=>{}, err: () => {}})
              if (this.target.indexOf('/auth') > -1) {
                return this.$router.push({path: '/', query: {_replace:true}});
              }
              return this.$router.push({path: this.target, query: {_replace:true}});
            }
          } else {
            this.$toast({
              message: res.msg,
              duration: 4000
            })
            setToken(null);
            return false
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.err-msg{
  margin-top: 30px;
  text-align: center;
  font-size: 30px;
}
</style>